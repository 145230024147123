import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import type { ReactElement } from 'react';

type LogoProps = StyleProps & {
  /**
   * The visual style of the logo.
   */
  variant: 'light' | 'dark';

  onPress?: () => void;
};

const ROOT = makeRootClassName('Logo');
const el = makeElementClassNameFactory(ROOT);

const LIGHT_LOGO_SRC = '/logos/svg/ca_logo_full_light.svg';
const DARK_LOGO_SRC = '/logos/svg/ca_logo_full_dark.svg';

const DEFAULT_PROPS = {
  variant: 'light',
};

const logoForVariant = (variant: LogoProps['variant']) => {
  switch (variant) {
    case 'light':
      return LIGHT_LOGO_SRC;
    case 'dark':
      return DARK_LOGO_SRC;
    default:
      throw new Error(`Unrecognized logo variant`);
  }
};

function Logo(props: LogoProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { focusProps, isFocusVisible } = useFocusRing();
  const buttonProps = p.onPress ? buttonize(p.onPress) : {};
  const behaviorProps = mergeProps(buttonProps, focusProps);

  return (
    <div
      {...behaviorProps}
      className={clsx(ROOT, `variant-${p.variant}`, {
        'has-action': p.onPress,
        'is-focus-visible': isFocusVisible,
      })}
    >
      <Image
        src={logoForVariant(p.variant)}
        alt="Chainabuse Logo"
        layout="fill"
        objectFit="contain"
        objectPosition="left"
        priority={true}
      />
    </div>
  );
}

export default Logo;
