import { MutationResult } from '@apollo/client';
import {
  CreateMarketingLeadInput,
  CreateMarketingLeadMutation,
  useCreateMarketingLeadMutation,
} from '../../../generated/graphql';

type UseSubscribe = [
  /**
   * Returns success state
   */
  (input: CreateMarketingLeadInput) => Promise<boolean | null>,
  MutationResult<CreateMarketingLeadMutation>
];

export const useSubscribe = (): UseSubscribe => {
  const [createMarketingLeadMutation, mutationData] =
    useCreateMarketingLeadMutation();

  const subscribe = async ({ email }: CreateMarketingLeadInput) => {
    try {
      const { data, errors } = await createMarketingLeadMutation({
        variables: { input: { email } },
      });

      if (errors) {
        throw errors;
      }

      return data?.createMarketingLead.success ?? null;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return [subscribe, mutationData];
};
