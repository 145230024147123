import * as RadixPopover from '@radix-ui/react-popover';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import {
  RadixTriggerToReactAriaAdaptor,
  RadixTriggerToReactAriaAdaptorProps,
} from '@/utils/radixTriggerToReactAriaAdaptor';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type PopoverProps = StyleProps &
  RadixPopover.PopoverProps &
  RadixTriggerToReactAriaAdaptorProps & {
    /**
     * Which side of the trigger the popover should be placed.
     * @default "bottom"
     */
    side?: 'top' | 'bottom' | 'left' | 'right';

    /**
     * The preferred alignment against the trigger
     * @default "end"
     */
    align?: 'start' | 'center' | 'end';

    /** The target for the popover */
    children: ReactNode;

    /** The content of the popover */
    content: ReactNode;
  };

const ROOT = makeRootClassName('Popover');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  side: 'bottom',
  align: 'end',
  hasReactAriaChildren: false,
} as const;

function Popover(props: PopoverProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <RadixPopover.Root open={p.open} onOpenChange={p.onOpenChange}>
      <RadixPopover.Trigger asChild className={el`trigger`}>
        {p.hasReactAriaChildren ? (
          <RadixTriggerToReactAriaAdaptor>
            {p.children}
          </RadixTriggerToReactAriaAdaptor>
        ) : (
          p.children
        )}
      </RadixPopover.Trigger>
      {p.content && (
        <RadixPopover.Content
          className={clsx(el`content`, p.className)}
          sideOffset={6}
          side={p.side}
          align={p.align}
        >
          {p.content}
        </RadixPopover.Content>
      )}
    </RadixPopover.Root>
  );
}

export default Popover;
