import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useMe } from '@/hooks';
import { getRouteForOrganizationMyFeed } from '@/types/routes';
import { HeaderToggle, HeaderToggleProps } from '@/components';

function MyFeedToggle(
  props: Pick<HeaderToggleProps, 'isSelected'>
): ReactElement {
  const { push } = useRouter();

  const { me } = useMe();
  if (!me || me.organizations.length === 0) return <></>;

  return (
    <HeaderToggle
      label="My Feed"
      options={me.organizations.map((o) => o.id)}
      getDisplayNameForOption={(id) =>
        me.organizations.find((o) => o.id === id)?.name
      }
      onSelect={(id) => push(getRouteForOrganizationMyFeed(id))}
      {...props}
    />
  );
}

export default MyFeedToggle;
