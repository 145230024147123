import clsx from 'clsx';
import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Link } from '@/components';
import { Mobile, Tablet } from '@/utils/responsive';
import {
  ABOUT_ROUTE,
  API_DOCS_URL,
  BROWSE_ALL_ROUTE,
  CONTACT_SUPPORT_ROUTE,
  FAQ_ROUTE,
  FILE_REPORT_ROUTE,
  GLOSSARY_ROUTE,
  GOT_SCAMMED_URL,
  LEADERBOARD_ROUTE,
  SAFETY_SUPPORT_CENTER_URL,
  PARTNER_CONTACT_SUPPORT_ROUTE,
} from '@/types/routes';

export type UsefulLinksProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('UsefulLinks');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function UsefulLinks(props: UsefulLinksProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Mobile>
        <div className={el`links-wrapper`}>
          <div className={el`links`}>
            <Link variant="white" size="xs" href={FILE_REPORT_ROUTE}>
              Report a Scam
            </Link>
            <Link variant="white" size="xs" href={LEADERBOARD_ROUTE}>
              Top Contributors
            </Link>
            <Link variant="white" size="xs" href={BROWSE_ALL_ROUTE}>
              View Reports
            </Link>
            <Link variant="white" size="xs" href={ABOUT_ROUTE}>
              About us
            </Link>
            <Link variant="white" size="xs" href={FAQ_ROUTE}>
              FAQs
            </Link>
            <Link variant="white" size="xs" href={CONTACT_SUPPORT_ROUTE}>
              Contact
            </Link>
          </div>
          <div className={el`links`}>
            <Link variant="white" size="xs" href={GOT_SCAMMED_URL}>
              Get Scammed?
            </Link>
            <Link variant="white" size="xs" href={SAFETY_SUPPORT_CENTER_URL}>
              Safety Support Center
            </Link>
            <Link variant="white" size="xs" href={API_DOCS_URL}>
              API Docs
            </Link>
            <Link variant="white" size="xs" href={CONTACT_SUPPORT_ROUTE}>
              Become a Partner
            </Link>
            <Link variant="white" size="xs" href={GLOSSARY_ROUTE}>
              Scam Glossary
            </Link>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className={el`links-wrapper`}>
          <div className={el`links`}>
            <Link variant="white" size="xs" href={FILE_REPORT_ROUTE}>
              Report a Scam
            </Link>
            <Link variant="white" size="xs" href={LEADERBOARD_ROUTE}>
              Top Contributors
            </Link>
            <Link variant="white" size="xs" href={BROWSE_ALL_ROUTE}>
              View Reports
            </Link>
          </div>
          <div className={el`links`}>
            <Link variant="white" size="xs" href={GOT_SCAMMED_URL}>
              Got Scammed?
            </Link>
            <Link variant="white" size="xs" href={SAFETY_SUPPORT_CENTER_URL}>
              Safety Support Center
            </Link>
            <Link variant="white" size="xs" href={API_DOCS_URL}>
              API Docs
            </Link>
            <Link
              variant="white"
              size="xs"
              href={PARTNER_CONTACT_SUPPORT_ROUTE}
            >
              Become a Partner
            </Link>
          </div>
          <div className={el`links`}>
            <Link variant="white" size="xs" href={ABOUT_ROUTE}>
              About us
            </Link>
            <Link variant="white" size="xs" href={FAQ_ROUTE}>
              FAQs
            </Link>
            <Link variant="white" size="xs" href={GLOSSARY_ROUTE}>
              Scam Glossary
            </Link>
            <Link variant="white" size="xs" href={CONTACT_SUPPORT_ROUTE}>
              Contact
            </Link>
          </div>
        </div>
      </Tablet>
    </div>
  );
}

export default UsefulLinks;
