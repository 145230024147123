import clsx from 'clsx';
import { ReactElement, useRef } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { PageType } from '@/types/page';
import { Desktop, Mobile } from '@/utils/responsive';
import { Link } from '@/components';
import {
  PARTNER_CONTACT_SUPPORT_ROUTE,
  API_DOCS_LINK,
  SAFETY_SUPPORT_CENTER_URL,
  GOT_SCAMMED_URL,
} from '@/types/routes';
import { Search } from '@/features/search';

import {
  getRouteForAddressSearch,
  getRouteForDomainSearch,
} from '@/types/routes';

export type SecondTierNavProps = StyleProps & {
  /**
   * The page type to determine the style of the second tier nav.
   */
  pageType?: PageType;
};

const ROOT = makeRootClassName('SecondTierNav');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function SecondTierNav(props: SecondTierNavProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const router = useRouter();

  const inputRef = useRef<HTMLInputElement>(null);

  const links = (
    <div className={el`links-wrapper`}>
      <NextLink href={GOT_SCAMMED_URL} passHref>
        <Link size="small" variant="white" className={el`link`}>
          Got Scammed?
        </Link>
      </NextLink>
      <NextLink href={SAFETY_SUPPORT_CENTER_URL} passHref>
        <Link size="small" variant="white" className={el`link`}>
          Safety Support Center
        </Link>
      </NextLink>
      <NextLink href={API_DOCS_LINK} passHref>
        <Link size="small" variant="white" className={el`link`}>
          API Docs
        </Link>
      </NextLink>
      <NextLink href={PARTNER_CONTACT_SUPPORT_ROUTE} passHref>
        <Link size="small" variant="white" className={el`link`}>
          Become a Partner
        </Link>
      </NextLink>
    </div>
  );

  return (
    <>
      <Mobile>
        <section className={el`second-tier-mobile`}>{links}</section>
      </Mobile>
      <Desktop>
        <div
          className={clsx([
            ROOT,
            p.className,
            { 'is-home': p.pageType === PageType.HOME },
          ])}
        >
          <div>
            <Search
              variant='transparent'
              onSearchAddress={(address, chain) =>
                router.push(getRouteForAddressSearch(address, chain))
              }
              onSearchDomain={(domain) =>
                router.push(getRouteForDomainSearch(domain))
              }
              inputRef={inputRef}
            />
          </div>
          {links}
        </div>
      </Desktop>
    </>
  );
}

export default SecondTierNav;