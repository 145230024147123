import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import React, { ReactElement, useState, RefObject, KeyboardEvent } from 'react';
import isURL from 'validator/lib/isURL';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { ChainType } from '@/types/chain';
import { makeElementClassNameFactory, makeRootClassName } from '@/utils';
import { useOptionalRef } from '@/hooks';
import { createSearch } from '@/assets/icons';
import TextFieldBase from '@/components/textfield/TextFieldBase';
import { Text } from '@/components';
import { isPossibleAddress } from '@/utils';

type SearchProps = {
  /** Handler to call when the user searches what looks like an address */
  onSearchAddress: (address: string, chain?: ChainType) => void;

  /** Handler to call when the user searches what looks like a url */
  onSearchDomain: (url: string) => void;

  /**
   * The input's ref
   */
  inputRef?: RefObject<HTMLInputElement>;

  /**
   * The text fields's visual appearance.
   * @default "default"
   */
  variant?:
    | 'default'
    | 'light'
    | 'transparent'
    | 'transparent-borderless'
    | 'comment';
};

const ROOT = makeRootClassName('SearchInput');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  placeholder: 'Check addresses or URLs',
} as const;

const SEARCH_ICON_PATH = createSearch;

function Search(props: SearchProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const inputRef = useOptionalRef<HTMLInputElement>(p.inputRef);

  const [isInvalid, updateIsInvalid] = useState<boolean>(false);
  //@TODO: should use isInvalid only after user press enter
  //the current behave is validate on change line 58

  const [keyword, setKeyword] = useState<string>('');

  const validate = (value: string) => {
    if (value === '') {
      return updateIsInvalid(false);
    }

    const isValid = isPossibleAddress(value) || isURL(value);
    updateIsInvalid(!isValid);
    return isValid;
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (keyword === '') {
      updateIsInvalid(false);
      return;
    }

    if (e.key === 'Enter') {
      validate(keyword);

      if (isURL(keyword)) {
        // sanitize any urls before they're submitted
        // (in case they contain any malicious url format)
        const sanitizedUrl = sanitizeUrl(keyword);
        return p.onSearchDomain?.(sanitizedUrl);
      }

      if (isPossibleAddress(keyword)) {
        return p.onSearchAddress?.(keyword);
      }

      if (!isURL(keyword) && !isPossibleAddress(keyword)) {
        updateIsInvalid(true);
      }
    }
  };

  const handleUpdateValue = async (value: string) => {
    setKeyword(value);
  };

  const state = useSearchFieldState({
    ...p,
    onChange: handleUpdateValue,
    onKeyDown: handleEnter,
  });

  const { labelProps, inputProps } = useSearchField(
    {
      ...p,
      type: 'search',
      placeholder: p.placeholder,
      value: keyword,
      label: 'search',
      onKeyDown: handleEnter,
    },
    state,
    inputRef
  );

  return (
    <div className={el`search`}>
      <TextFieldBase
        variant={p.variant ?? 'default'}
        size="medium"
        startIconPath={SEARCH_ICON_PATH}
        validationState={isInvalid ? 'invalid' : 'valid'}
        autoComplete="off"
        labelProps={labelProps}
        inputProps={inputProps}
        inputRef={inputRef}
        inputClassName={el`search-input`}
      />
      {isInvalid && (
        <Text className={el`unsupported`}>Incorrect or unsupported entry.</Text>
      )}
    </div>
  );
}

export default Search;
