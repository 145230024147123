import * as Radix from '@radix-ui/react-dialog';
import { DialogProps } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type TopSheetProps = StyleProps &
  DialogProps & {
    /** The top sheet's contents */
    children?: ReactNode;
  };

const ROOT = makeRootClassName('TopSheet');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function TopSheet(props: TopSheetProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <Radix.Root open={p.open} onOpenChange={p.onOpenChange}>
      <Radix.Overlay asChild>
        <div className={el`overlay`} />
      </Radix.Overlay>
      <Radix.Content
        className={clsx([ROOT, p.className], {
          'is-visible': p.open,
        })}
      >
        <main className={el`sheet`}>{p.children}</main>
      </Radix.Content>
    </Radix.Root>
  );
}

export default TopSheet;
