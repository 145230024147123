import {
  KrollReportSupportOptInKind,
  ReportSupportPartner,
} from '@/generated/graphql';
import { ChainType } from './chain';
import { ScamCategory } from './scam-categories';

export enum PageType {
  HOME = 'home',
  SEARCH_ADDRESS = 'search-address',
  SEARCH_DOMAIN = 'search-domain',
  BROWSE_ALL = 'browse-all',
  BROWSE_CATEGORY = 'browse-category',
  BROWSE_CHAIN = 'browse-chain',
  FILE_REPORT = 'file-report',
  EDIT_REPORT = 'edit-report',
  PROFILE = 'profile',
  PROFILE_SELF = 'profile-self',
  PROFILE_OTHER = 'profile-other',
  REPORT_BY_ID = 'report-by-id',
  MY_FEED = 'my-feed',
  SUBDOMAIN_FEED = 'subdomain-feed',
  ABOUT = 'about',
  FAQ = 'faq',
  GLOSSARY = 'glossary',
  CONTACT = 'contact',
  LEADERBOARD = 'leaderboard',
  SUPPORT_PARTNER_OPT_IN = 'support-partner-opt-in',
}

type HomePageProps = {
  pageType: PageType.HOME;
};

type SearchDomainPageProps = {
  pageType: PageType.SEARCH_DOMAIN;
  domain: string;
};

type SearchAddressPageProps = {
  pageType: PageType.SEARCH_ADDRESS;
  address: string;
  chain?: ChainType;
};

type BrowseAllPageProps = {
  pageType: PageType.BROWSE_ALL;
};

type SimplePageProps = {
  pageType:
    | PageType.BROWSE_ALL
    | PageType.FILE_REPORT
    | PageType.ABOUT
    | PageType.FAQ
    | PageType.GLOSSARY
    | PageType.CONTACT
    | PageType.LEADERBOARD;
};

type CategoryPageProps = {
  pageType: PageType.BROWSE_CATEGORY;
  category: ScamCategory;
};

type ChainPageProps = {
  pageType: PageType.BROWSE_CHAIN;
  chain: ChainType;
};

export type ProfilePageProps = {
  pageType: PageType.PROFILE | PageType.PROFILE_SELF | PageType.PROFILE_OTHER;
  username: string;
};

export type ReportByIdPageProps = {
  pageType: PageType.REPORT_BY_ID;
  reportId: string;
};

export type ReportForMyFeedPageProps = {
  pageType: PageType.MY_FEED;
  reportId: string;
  orgId: string;
};

export type EditReportPageProps = {
  pageType: PageType.EDIT_REPORT;
  reportId: string;
};

export type MyFeedPageProps = {
  pageType: PageType.MY_FEED;
  orgId: string;
};

export type SubdomainPageProps = {
  pageType: PageType.SUBDOMAIN_FEED;
  orgId?: string;
  orgSlug?: string;
};

export type SupportPartnerOptInPageProps = {
  pageType: PageType.SUPPORT_PARTNER_OPT_IN;
  partnerSlug: ReportSupportPartner;
  reportId: string;
  kind?: KrollReportSupportOptInKind;
};

export type BrowsePageProps =
  | BrowseAllPageProps
  | CategoryPageProps
  | ChainPageProps
  | ReportByIdPageProps
  | ReportForMyFeedPageProps
  | SubdomainPageProps;

export type SearchResultsPageProps =
  | SearchAddressPageProps
  | SearchDomainPageProps;

export type PageLoadingProps = {
  isLoading: true;
};

export type PageProps =
  | HomePageProps
  | SearchDomainPageProps
  | SearchAddressPageProps
  | SimplePageProps
  | CategoryPageProps
  | ChainPageProps
  | ProfilePageProps
  | ReportByIdPageProps
  | EditReportPageProps
  | MyFeedPageProps
  | ReportForMyFeedPageProps
  | SubdomainPageProps
  | SupportPartnerOptInPageProps;
