import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, Text, TextField } from '@/components';
import { PRIVACY_POLICY_URL } from '@/types/routes';
import { useSubscribe } from '../../../../../home-page/hooks/useSubscribe';
import { UsefulLinks } from './components/useful-links';

export type ContactSectionProps = StyleProps & {
  /** Action handler to call when user presses subscribe button */
  onSubscribe: (email: string) => void;
};

const ROOT = makeRootClassName('ContactSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function ContactSection(props: ContactSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubscribe = () => {
    if (isEmail(email)) {
      p.onSubscribe(email);
      setEmail('');
      setIsSubscribed(true);
    }
  };

  return (
    <section className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`links-section`}>
          <UsefulLinks />
        </div>
        <div className={el`subscribe-section`}>
          <div className={el`textfield-and-bottom-label`}>
            <Text type="h4" className={el`headline`}>
              Sign up and stay up to date with our latest product updates.
            </Text>
            {!isSubscribed && (
              <div className={el`email-wrapper`}>
                <TextField
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  variant="light"
                  value={email}
                  onChange={setEmail}
                  className={'flex-grow'}
                />
                <Button
                  variant="secondary"
                  size="large"
                  className={el`subscribe-button`}
                  onPress={handleSubscribe}
                >
                  Subscribe
                </Button>
              </div>
            )}
            {isSubscribed && (
              <Text className={el`subscribe-confirmation`} type="h4">
                Thank you for subscribing.
              </Text>
            )}
            <div className={el`privacy-text-wrapper`}>
              <div className={el`privacy-disclaimer`}>
                You can unsubscribe at any time.
              </div>
              <div className={el`read-our-privacy-policy`}>
                Read our{' '}
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ContactSectionContainer(): ReactElement {
  const [subscribe] = useSubscribe();

  const handleSubscribe = (email: string): void => {
    subscribe({ email });
  };
  return <ContactSection onSubscribe={handleSubscribe} />;
}

export default ContactSectionContainer;
