import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text, Popover } from '@/components';
import { createChevronDown, createChevronRight } from '@/assets/icons';
import { Icon } from '../icon';
import { Tablet, Mobile } from '../../utils/responsive';

const DROPDOWN_ICON = createChevronDown;
const MOBILE_DROPDOWN_ICON = createChevronRight;

export type HeaderToggleProps = StyleProps & {
  /** The toggle button's label */
  label: string;

  /** List of possible options */
  options: string[];

  /** Handler to call when an option gets selected */
  onSelect: (option: string) => void;

  /*
   * If its mobile, it should display a Why chainabuse for /about route
   */
  isMobile?: boolean;

  /** Handler to call to display the options's name */
  getDisplayNameForOption?: (option: string) => string | undefined;

  /**
   * Whether the header toggle is selected
   * @default false
   */
  isSelected?: boolean;
};

const ROOT = makeRootClassName('HeaderToggle');
const el = makeElementClassNameFactory(ROOT);

type OptionRowProps = {
  onSelectOption: (option: string) => void;
  option: string;
  children: string;
};

const OptionRow = (props: OptionRowProps): ReactElement => {
  const { hoverProps, isHovered } = useHover({ isDisabled: false });
  const { pressProps, isPressed } = usePress({
    isDisabled: false,
    onPress: () => props.onSelectOption(props.option),
  });
  const behaviorProps = mergeProps(hoverProps, pressProps);

  return (
    <div
      {...behaviorProps}
      className={clsx(el`option`, {
        'is-hovered': isHovered,
        'is-pressed': isPressed,
      })}
    >
      <span className={el`label`}>{props.children}</span>
    </div>
  );
};

const DEFAULT_PROPS = {
  isSelected: false,
} as const;

function HeaderToggle(props: HeaderToggleProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const options = p.options.map((option, idx) => {
    const displayName = p.getDisplayNameForOption?.(option) ?? option;
    return (
      <OptionRow
        key={`${option}-${idx}`}
        option={option}
        onSelectOption={(option) => {
          p.onSelect?.(option);
          toggleOpen();
        }}
      >
        {displayName}
      </OptionRow>
    );
  });

  return (
    <React.Fragment>
      <Tablet>
        <Popover content={options} open={open} onOpenChange={toggleOpen}>
          <button
            className={clsx(ROOT, {
              'is-selected': p.isSelected,
              'is-open': open,
            })}
          >
            <Text className={el`label`}>{p.label}</Text>
            <Icon content={DROPDOWN_ICON} className={el`dropdown-icon`} />
          </button>
        </Popover>
      </Tablet>
      <Mobile>
        <div
          className={clsx(ROOT, {
            'is-selected': p.isSelected,
            'is-open': open,
          })}
          onClick={toggleOpen}
        >
          <Text className={el`label`}>{p.label}</Text>
          <Icon
            content={open ? DROPDOWN_ICON : MOBILE_DROPDOWN_ICON}
            className={el`dropdown-icon`}
          />
        </div>
        {open && <div className={el`options`}>{options}</div>}
      </Mobile>
    </React.Fragment>
  );
}

export default HeaderToggle;
