import React, { ReactElement, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Button, Text } from '@/components';
import { makeElementClassNameFactory, makeRootClassName } from '@/utils';
import { Header } from '../layout/components/header';
import { Footer } from '../layout/components/footer';

const ROOT = makeRootClassName('ErrorPage');
const el = makeElementClassNameFactory(ROOT);

type ErrorPageProps = {
  /** The error's title */
  title?: string;
  /** The error's description */
  description?: string;
  /** The error body's children. Suitable for adding action buttons */
  children?: ReactNode;
  /** Link to go to when user clicks TRY AGAIN. Only used when children is undefined */
  backPage?: string;
};

const DEFAULT_PROPS = {
  title: 'Unknown error',
  description:
    "Something went wrong and we couldn't complete your request. Please try again later.",
  backPage: '/',
} as const;

export function ErrorPageBody(props: ErrorPageProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={el`wrapper`}>
      <div className={el`content`}>
        <div className={el`body`}>
          <Text type="h2">{p.title}</Text>
          <Text className={el`description`}>{p.description}</Text>
          <div className={el`buttons`}>{p.children}</div>
        </div>
      </div>
    </div>
  );
}

export default function ErrorPage(props: ErrorPageProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { push, back } = useRouter();

  return (
    <div className={ROOT}>
      <Header />
      <div className={el`separator`} />
      <ErrorPageBody title={p.title} description={p.description}>
        {p.children || (
          <>
            <Button onPress={back}>TRY AGAIN</Button>
            <Button onPress={() => push(p.backPage)} variant="secondary">
              CANCEL
            </Button>
          </>
        )}
      </ErrorPageBody>
      <Footer />
    </div>
  );
}
