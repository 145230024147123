import clsx from 'clsx';
import NextImage from 'next/image';
import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text } from '@/components';

export type BackedByProps = StyleProps;

const ROOT = makeRootClassName('BackedBy');
const el = makeElementClassNameFactory(ROOT);

const TRM_LOGO = '/images/partners/trm.svg';
const TRM_LOGO_WIDTH = 69;
const TRM_LOGO_HEIGHT = 21;

const AAVE_LOGO = '/images/partners/aave.svg';
const AAVE_LOGO_WIDTH = 53;
const AAVE_LOGO_HEIGHT = 14;

const BINANCE_LOGO = '/images/partners/binanceus-white.svg';
const BINANCE_LOGO_WIDTH = 117;
const BINANCE_LOGO_HEIGHT = 18;

const CIRCLE_LOGO = '/images/partners/circle.svg';
const CIRCLE_LOGO_WIDTH = 85;
const CIRCLE_LOGO_HEIGHT = 22;

const CIVIC_LOGO = '/images/partners/civic.svg';
const CIVIC_LOGO_WIDTH = 71;
const CIVIC_LOGO_HEIGHT = 25;

const HEDERA_LOGO = '/images/partners/hedera.svg';
const HEDERA_LOGO_WIDTH = 93;
const HEDERA_LOGO_HEIGHT = 29;

const OPENSEA_LOGO = '/images/partners/opensea.svg';
const OPENSEA_LOGO_WIDTH = 93;
const OPENSEA_LOGO_HEIGHT = 25;

const RANSOMWHERE_LOGO = '/images/partners/ransomwhere.svg';
const RANSOMWHERE_LOGO_WIDTH = 128;
const RANSOMWHERE_LOGO_HEIGHT = 22;

const SOLANA_LOGO = '/images/partners/solana.svg';
const SOLANA_LOGO_WIDTH = 92;
const SOLANA_LOGO_HEIGHT = 11;

const DEFAULT_PROPS = {} as const;

function BackedBy(props: BackedByProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <section className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`backed-by-wrapper`}>
          <Text className={el`headline`} type="h6">
            Backed By
          </Text>
        </div>
        <div className={el`logos`}>
          <NextImage
            src={TRM_LOGO}
            width={TRM_LOGO_WIDTH}
            height={TRM_LOGO_HEIGHT}
            className={el`image`}
            alt="TRM logo"
          />
          <NextImage
            src={SOLANA_LOGO}
            width={SOLANA_LOGO_WIDTH}
            height={SOLANA_LOGO_HEIGHT}
            className={el`image`}
            alt="Solana logo"
          />
          <NextImage
            src={CIRCLE_LOGO}
            width={CIRCLE_LOGO_WIDTH}
            height={CIRCLE_LOGO_HEIGHT}
            className={el`image`}
            alt="Circle logo"
          />
          <NextImage
            src={OPENSEA_LOGO}
            width={OPENSEA_LOGO_WIDTH}
            height={OPENSEA_LOGO_HEIGHT}
            className={el`image`}
            alt="Opensea logo"
          />
          <NextImage
            src={AAVE_LOGO}
            width={AAVE_LOGO_WIDTH}
            height={AAVE_LOGO_HEIGHT}
            className={el`image`}
            alt="Aave logo"
          />
          <NextImage
            src={BINANCE_LOGO}
            width={BINANCE_LOGO_WIDTH}
            height={BINANCE_LOGO_HEIGHT}
            className={el`image`}
            alt="Binance logo"
          />
          <NextImage
            src={CIVIC_LOGO}
            width={CIVIC_LOGO_WIDTH}
            height={CIVIC_LOGO_HEIGHT}
            className={el`image`}
            alt="Civic logo"
          />
          <NextImage
            src={HEDERA_LOGO}
            width={HEDERA_LOGO_WIDTH}
            height={HEDERA_LOGO_HEIGHT}
            className={el`image`}
            alt="Hedera logo"
          />
          <NextImage
            src={RANSOMWHERE_LOGO}
            width={RANSOMWHERE_LOGO_WIDTH}
            height={RANSOMWHERE_LOGO_HEIGHT}
            className={el`image`}
            alt="Rasomwhe.re logo"
          />
        </div>
      </div>
    </section>
  );
}

export default BackedBy;
