import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { createClose, createMenu } from '@/assets/icons';
import { Button, Link, Logo, TopSheet } from '@/components';
import { PreAuthModal, Search } from '@/features';
import { PageType } from '@/types/page';
import {
  BROWSE_ALL_ROUTE,
  FILE_REPORT_ROUTE,
  LEADERBOARD_ROUTE,
  getRouteForAddressSearch,
  getRouteForDomainSearch,
} from '@/types/routes';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Desktop, UpToDesktop } from '@/utils/responsive';
import { SecondTierNav } from '../second-tier-nav';
import AboutToggle from './AboutToggle';
import MyFeedToggle from './MyFeedToggle';

export type HeaderProps = StyleProps & {
  /**
   * The page type the header is being rendered on.
   * Optional unless you need the header to change appearance
   * based on the rendered page
   */
  pageType?: PageType;
};

const ROOT = makeRootClassName('Header');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const CLOSE_ICON = createClose;
const HAMBURGER_ICON = createMenu;
const ROUTE_CHANGE_START_EVENT = 'routeChangeStart';

const Header = (props: HeaderProps): ReactElement => {
  const p = { ...DEFAULT_PROPS, ...props };
  const { events, asPath, push } = useRouter();
  const [sheetOpen, setSheetOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const toggleSheet = () => setSheetOpen(!sheetOpen);

  useEffect(() => {
    const closeSheet = () => setSheetOpen(false);

    events.on(ROUTE_CHANGE_START_EVENT, closeSheet);
    return () => events.off(ROUTE_CHANGE_START_EVENT, closeSheet);
  }, [events]);

  const isAboutPage =
    p.pageType &&
    [
      PageType.ABOUT,
      PageType.FAQ,
      PageType.GLOSSARY,
      PageType.CONTACT,
    ].includes(p.pageType);

  const links = (
    <>
      <MyFeedToggle />
      <NextLink href={FILE_REPORT_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.FILE_REPORT}>
          Report a Scam
        </Link>
      </NextLink>
      <NextLink href={LEADERBOARD_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.LEADERBOARD}>
          Top Contributors
        </Link>
      </NextLink>
      <NextLink href={BROWSE_ALL_ROUTE} passHref>
        <Link variant="white" isSelected={p.pageType === PageType.BROWSE_ALL}>
          View Reports
        </Link>
      </NextLink>
      <AboutToggle isSelected={isAboutPage} />
    </>
  );

  const renderActions = (): ReactElement => {
    return (
      <>
        <UpToDesktop>
          <div
            {...buttonize(toggleSheet)}
            className={el`menu-button-tap-target`}
          >
            <Button
              size="custom"
              variant="text-white"
              startIcon={sheetOpen ? CLOSE_ICON : HAMBURGER_ICON}
              onPress={toggleSheet}
              startIconSize="custom"
              startIconClassName={el`menu-icon`}
              className={el`menu-button`}
              aria-label={
                sheetOpen ? 'Close navigation menu' : 'Open navigation menu'
              }
              aria-expanded={sheetOpen ? true : false}
            />
          </div>
          <TopSheet
            open={sheetOpen}
            onOpenChange={toggleSheet}
            className={el`mobile-sheet`}
          >
            <div className={el`search-wrapper`}>
              <Search
                variant="transparent"                            
                onSearchAddress={(address, chain) =>
                  push(getRouteForAddressSearch(address, chain))
                }
                onSearchDomain={(domain) =>
                  push(getRouteForDomainSearch(domain))
                }
                inputRef={inputRef}
              />
            </div>
            <section className={el`links`}>{links}</section>
            <SecondTierNav />
            <section>
              <PreAuthModal returnTo={asPath} />
            </section>
          </TopSheet>
        </UpToDesktop>
        <Desktop>
          <div className={el`links`}>
            {links}
            <PreAuthModal returnTo={asPath} />
          </div>
        </Desktop>
      </>
    );
  };

  return (
    <header
      className={clsx(ROOT, {
        'is-home': p.pageType === PageType.HOME,
        'is-top-sheet-open': sheetOpen,
      })}
    >
      <NextLink href="/" passHref>
        <a>
          <Logo variant="light" />
        </a>
      </NextLink>
      {renderActions()}
    </header>
  );
};
export default Header;
