import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { HeaderToggle, HeaderToggleProps } from '@/components';
import {
  ABOUT_ROUTE,
  CONTACT_SUPPORT_ROUTE,
  FAQ_ROUTE,
  GLOSSARY_ROUTE,
} from '@/types/routes';

const getDisplayNameForOption = (id: string): string => {
  switch (id) {
    case ABOUT_ROUTE:
      return 'About us';
    case FAQ_ROUTE:
      return 'FAQs';
    case GLOSSARY_ROUTE:
      return 'Scam Glossary';
    case CONTACT_SUPPORT_ROUTE:
      return 'Contact';
    default:
      return '';
  }
};

function AboutToggle(
  props: Pick<HeaderToggleProps, 'isSelected'>
): ReactElement {
  const options = [
    ABOUT_ROUTE,
    FAQ_ROUTE,
    GLOSSARY_ROUTE,
    CONTACT_SUPPORT_ROUTE,
  ];

  const { push } = useRouter();
  return (
    <HeaderToggle
      isSelected
      label="About"
      options={options}
      getDisplayNameForOption={getDisplayNameForOption}
      onSelect={(id) => push(id)}
      {...props}
    />
  );
}
export default AboutToggle;
